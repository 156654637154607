.metadata-cat {
  height: 100%;
  // background: #fff;
  // border-radius: 3px;
  // padding: 0 $pt-grid-size * 2 $pt-grid-size * 2;
}

.metadata-key {
  width: 50%;
}

.metadata-page {
  p {
    margin-top: 40px;
    margin-bottom: 0px;
  }

  .metadata-table {
    width: 100%;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
      }

      li:nth-child(2n) {
        padding-top: 1em;
      }
    }
  }

  .download-buttons {
    margin-bottom: 30px;
  }

  .info-icon {
    cursor: pointer;
    padding-left: 5px;
    color: #444;
  }

  .circularized-icon {
    cursor: pointer;
    padding-bottom: 2px;
    padding-right: 5px;
    color: orange;
  }
}

.contigs-dialog {
  background: white;
}

.metadata-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}
