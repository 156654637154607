$sequence-search-textarea-height: 400px;

.sequence-search-textarea {
  height: $sequence-search-textarea-height;
  width: 100%;
  background: $white;
  box-shadow: $pt-input-box-shadow;
  border: none;
  border-radius: $pt-border-radius;
  position: relative;
  margin-bottom: 10px;
  font-family: monospace;
  display: inline-block;
  overflow: hidden;
  -webkit-text-size-adjust: none;

  .backdrop {
    position: absolute;
    top: 0;
    right: -100px;
    bottom: 0;
    left: 0;
    padding-right: 100px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .highlights {
    width: auto;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: transparent;
    overflow: hidden;
    // These 2 rules are Chrome only
    padding: 19px;
    // To match the default border of a textarea
    border: 1px solid transparent;
  }

  .highlights,
  textarea {
    background: none transparent;
    font: 16px/22px monospace;
  }

  mark {
    color: transparent;
  }

  textarea {
    width: 100%;
    min-height: $sequence-search-textarea-height;
    display: block;
    position: relative;
    margin: 0;
    padding: 20px;
    border-radius: 0;
    border: none;
    overflow-x: hidden;
    overflow-y: auto;
    resize: none;
  }
}
