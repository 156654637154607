.annotation-track {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;

  &.annotation-track--comparison {
    margin-left: 60px;
  }

  &__placeholder {
    background-color: #f8f8f8;
    height: 66px;
  }

  &__genome-label {
    height: 60px;
    position: absolute;
    top: 16px;
    left: -54px;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    overflow: hidden;
    width: 66px;
    text-overflow: ellipsis;
    text-align: center;
    transform: rotate(-90deg);
    transform-origin: 30% 50%;
    border-bottom: 1px lightgray solid;
  }

  &__track {
    width: 100%;
    overflow-x: auto;
    height: 66px;
    overflow-y: hidden;
    position: relative;
    padding-top: 20px;
    top: -20px;
    padding-bottom: 50px;
    margin-bottom: -50px;
    scrollbar-width: none;

    polygon {
      cursor: pointer;
    }
  }

  &__track::-webkit-scrollbar {
    display: none;
  }

  &__container {
    height: 66px;
    margin: 0 auto;
  }

  .legend-table-element {
    padding: 3px;
  }
}

.legend-popover {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $pt-grid-size;
}

.legend-inner {
  width: 16px;
  height: 16px;
  border: 1px solid white;
}
