@media (min-width: 720px) {
  .app-container.homepage {
    background-image: url("/static/atcc/dna-blob@2x.jpg");
    background-position: right -100px bottom;
    background-repeat: no-repeat;
    background-size: 65%;
  }
}

.atcc-homepage {
  display: flex;
  flex-wrap: wrap;
  padding-left: 5%;
  overflow-x: hidden;
  overflow-y: hidden;
  border-top: 2px solid $purple;

  .welcome,
  .hero-image {
    width: 50%;
  }

  .recently-updated {
    width: 100%;
    padding-top: 20px;
  }

  .hero-image {
    text-align: right;
    height: 700px;

    img {
      height: 100%;
    }
  }

  h1 {
    font-size: 2rem;
    line-height: 1.5;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 1.8em;
  }

  .welcome {
    padding-top: 40px;
    padding-right: 1rem;
  }

  .view-genomes {
    letter-spacing: 1px;
    color: $purple;
  }

  .genome-tile-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .genome-icon {
      margin-bottom: 10px;
    }

    .genome-icon {
      float: left;
      height: 50px;
      width: 50px;
      padding-bottom: 4px;
      background-color: #ebf1f6;
      border-radius: 2px;
    }

    .genome-info {
      float: left;
      margin-left: 10px;
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      color: $pt-text-color;
    }

    .genome-subtitle {
      color: dimgray;
    }
  }
}
