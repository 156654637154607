.generic-link-card {
  align-self: end;
  margin-bottom: $pt-grid-size * 3;

  &__header {
    font-weight: 600;
    display: flex;
    margin-bottom: $pt-grid-size * 0.75;
    justify-content: space-between;
    color: $purple;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: adjust-color($gray1, $alpha: -0.9);
    padding: 10px;
    border-radius: 3px;
  }

  &__metadata {
    display: flex;
    flex-direction: column;
    font-size: 90%;
    margin-top: 10px;

    span {
      margin-bottom: 10px;
    }
  }

  button {
    margin-right: 0;
  }
}
