$row-height: 50px;

.viral-variants-table {
  background-color: $white;
  border-radius: $pt-border-radius;
  width: 100%;

  .link-icon {
    color: #3f9693;
    cursor: pointer;
  }

  .gene-product-cell {
    height: calc(#{$row-height} - 12px); // 6px padding top and bottom
    overflow: auto;
    text-align: left;
    display: flex;
    // safe doesn't exist in older browsers so IE11 users will have some text a bit cut off at the time
    // if the cell has a lot of content
    align-items: safe center;
  }

  .annotation-row {
    &--selected {
      background-color: lightgray;
    }
  }

  &.bp4-html-table td {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  thead {
    display: block;
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    width: 100%;

    tr {
      display: flex;
    }

    th {
      vertical-align: inherit;
      padding-left: 4px;
      padding-right: 4px;
      text-align: left;
    }
  }

  // There is one more column in comparison
  .annotations-table__header--comparison tr {
    th:nth-child(5) {
      text-align: left;
      flex: inherit;
    }

    th:nth-child(6) {
      text-align: left;
      flex: 1;
      width: 100%; // For IE11
      min-width: inherit;
      max-width: inherit;
    }
  }

  tbody {
    display: block;
    overflow-y: scroll;
    max-height: 500px;

    td {
      max-height: $row-height;
      height: $row-height;
      vertical-align: inherit;
      text-align: left;
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  tr {
    td:nth-child(1),
    th:nth-child(1) {
      max-width: 120px;
      min-width: 120px;
      padding-left: $pt-grid-size * 2;
      text-align: center;
    }
    td:nth-child(2),
    th:nth-child(2) {
      max-width: 120px;
      min-width: 120px;
      text-align: center;
    }
    td:nth-child(3),
    th:nth-child(3) {
      max-width: 120px;
      min-width: 120px;
    }
    td:nth-child(4),
    th:nth-child(4) {
      max-width: 120px;
      min-width: 120px;
      text-align: center;
    }
    td:nth-child(5),
    th:nth-child(5) {
      max-width: 120px;
      min-width: 120px;
      text-align: center;
    }
    td:nth-child(6),
    th:nth-child(6) {
      max-width: 120px;
      min-width: 120px;
    }
    td:nth-child(7),
    th:nth-child(7) {
      width: 100%;
    }
    td:nth-child(8),
    th:nth-child(8) {
      max-width: 145px;
      min-width: 145px;
      padding-right: 16px;
    }
    th:nth-child(8) {
      padding-left: 12px;
    }
    td:nth-child(9),
    th:nth-child(9) {
      // 0px min-width because the inclusion is optional
      max-width: 90px;
      min-width: 0px;
    }
  }
}
