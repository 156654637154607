.timer-5s {
  padding-right: 36px;
  position: relative;

  &__counter {
    pointer-events: none;
    content: " ";
    display: block;
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      position: absolute;
      content: "5";
      width: 24px;
      text-align: center;
      height: 24px;
      line-height: 24px;
      font-size: 11px;
      opacity: 0.75;

      animation-name: timer-5s-text-anim;
      animation-duration: 5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
    }
  }

  circle {
    transform: rotate(90deg) scaleX(-1);
    transform-origin: 12px 12px;
    fill: none;
    stroke: white;
    stroke-width: 2px;
    stroke-dasharray: 2 * 10 * 3.1415926535px;
    stroke-dashoffset: 0px;

    animation-name: timer-5s-circle-anim;
    animation-duration: 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
}

@keyframes timer-5s-circle-anim {
  from {
    stroke-dashoffset: 0px;
  }

  to {
    stroke-dashoffset: 2 * 10 * 3.1415926535px;
  }
}

@keyframes timer-5s-text-anim {
  0% {
    content: "5";
  }

  30% {
    content: "4";
  }

  50% {
    content: "3";
  }

  70% {
    content: "2";
  }

  90% {
    content: "1";
  }

  100% {
    content: "0";
  }
}
