$footer-height: 50px;

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .app-container {
    flex: 1 0 auto;
  }
}

#atcc-footer {
  margin-top: 5rem;
  flex-shrink: 0;
  background: $grey-bg;
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;

  &.homepage {
    margin-top: 0;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  img {
    height: 48px;
    vertical-align: middle;
    margin-left: 30px;
  }

  .powered-by {
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;

    span {
      color: black;
    }
  }

  .footer-logos {
    margin-bottom: 1rem;
  }

  .certs {
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    span {
      margin-left: 1rem;
      font-size: 0.7rem;
    }
    span + span:before {
      content: " | ";
      margin-right: 1rem;
    }
  }
}

.navbar-brand {
  border-right: none;
  padding-right: 0;
}
.navbar {
  background: #fff;
  border-bottom: solid 1px rgb(237, 238, 239);
  box-shadow: none;
  display: flex;
  padding: 0 50px;

  .nav-item {
    align-items: center;
    display: flex;
    color: black;
    cursor: pointer;
    height: 100%;
    margin-left: 30px;

    &.nav-header-text {
      margin-left: 0px;
    }
  }
}

.subscribe-button {
  margin-right: 44px;
  position: relative;

  &::after {
    display: block;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    margin-right: -30px;
    margin-top: -25px;
    width: 1px;
    height: 64px;
    border-left: 1px solid rgb(237, 238, 239);
    pointer-events: none;
  }
}

.navbar-profile {
  margin-left: auto;
  padding-left: 50px;
}

.navbar-brand {
  border-right: solid 1px rgb(237, 238, 239);
  display: flex;
  float: none;
  align-items: center;
  padding-right: 50px;

  img {
    margin: 10px 0px 0px 0px;
    height: 45px;
  }
}

.navbar {
  border-bottom: none;

  @media (max-width: $phone-breakpoint) {
    padding: 0 10px;

    .navbar-profile {
      padding-left: 0;
    }
  }

  .nav-item {
    letter-spacing: 1px;
  }

  img {
    height: 54px;
  }
}

.navbar-profile {
  .bp4-button.bp4-minimal:active,
  .bp4-button.bp4-minimal.bp4-active,
  .bp4-button.bp4-minimal:hover {
    background: none;
  }
}

.bp4-popover2.nav-item-genomes-submenu,
.bp4-popover2.nav-item-documentation-submenu {
  margin-top: -20px !important; // Blueprint.js is using !important so we need use it too
}

.bp4-tab[aria-selected="true"] {
  color: $purple;
  box-shadow: inset 0 -3px 0 $purple;
}

.bp4-tab:hover {
  color: $purple !important;
  box-shadow: inset 0 -3px 0 $purple;
}
