.related-genomes {
  &__container {
    margin-bottom: 40px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    grid-column-gap: 20px;
    margin-top: 2rem;
  }
}
