.quality-control {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 40px;

  > p {
    margin-bottom: 0;
  }

  div:first-child {
    -ms-grid-column: 1;
  }

  div:last-child {
    -ms-grid-column: 2;
  }

  .sequencing-type {
    font-size: 26px;
  }

  &__description {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  &__checks {
    margin-top: 2rem;
    max-width: 640px;
  }

  &__check {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;

    &__name {
      font-size: 1.1rem;
    }

    &__result {
      font-weight: bold;
      color: rgb(73, 73, 73);
      margin-left: auto;
    }
  }

  .info-icon {
    margin-left: 10px;
    margin-bottom: 3px;
  }
}
