.sequence-search-page {
  h1 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  .page-header p {
    margin: 0;
    max-width: 50%;
    padding-right: $pt-grid-size * 2;
  }
}

.sequence-search {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: $pt-grid-size * 2;
  margin: 0 ($pt-grid-size * 5);

  h2 {
    margin: 0 0 20px;
    line-height: 36px;
    font-size: 1.5rem;
  }

  p {
    margin: 0;
  }

  &--empty {
    font-style: italic;
  }

  &--loading {
    text-align: center;
  }

  &__input {
    -ms-grid-column: 1;
  }
  &__results {
    -ms-grid-column: 2;
  }

  .search-submit {
    display: flex;
    align-items: flex-start;

    .search-text {
      margin-right: auto;
      color: $pt-text-color-disabled;
      width: 75%;
    }
  }

  .results-list,
  &--loading {
    border-radius: $pt-border-radius;
    background: $white;
    box-shadow: $pt-input-box-shadow;
    padding: ($pt-grid-size * 2);
    max-height: 600px;
    overflow: scroll;
  }
}
