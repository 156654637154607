.page-header {
  background: #fff;
  padding: ($pt-grid-size * 2) 50px;

  @media (max-width: $phone-breakpoint) {
    padding: $pt-grid-size 10px;
  }

  border-bottom: solid 1px rgb(237, 238, 239);
  margin-bottom: $pt-grid-size * 3;

  &__main {
    display: flex;
    margin: 0 auto;
    align-items: center;
  }

  &__name {
    color: #444;
    margin: 0;
    order: 1;
    font-weight: normal;
  }

  .subheader-text {
    margin: 0;
    order: 2;

    h3 {
      color: gray;
      font-style: italic;
      font-size: large;
      font-weight: normal;
      margin: 0 0 0 $pt-grid-size * 2;
    }
  }

  .bp4-icon-share {
    vertical-align: middle;
  }
}
