:root {
  --stroke: 300;
  --logo-light: #bbd8d3;
  --logo-dark: #9fb9b9;
}

g.logo {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

#loading-icon-svg {
  width: 112px;
  height: 112px;
}

#loading-icon-svg path {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  stroke-width: 13px;
  fill-opacity: 0;
  fill-rule: nonzero;
  stroke-line-join: miter;
  stroke-linecap: square;
}

#loading-icon-svg path.background-logo-path {
  stroke-dashoffset: 0;
  stroke-opacity: 0.8;
}

#background-half-logo1 {
  stroke: #f7f6ef;
}

#background-half-logo2 {
  stroke: #f3f3ee;
}

#half-logo1 {
  stroke: var(--logo-light);
  -webkit-animation: 6s linear infinite normal loading-animation-1;
  -moz-animation: 6s linear infinite normal loading-animation-1;
  animation: 6s linear infinite normal loading-animation-1;
}

#half-logo2 {
  stroke: var(--logo-dark);
  -webkit-animation: 6s linear infinite normal loading-animation-2;
  -moz-animation: 6s linear infinite normal loading-animation-2;
  animation: 6s linear infinite normal loading-animation-2;
}

.fa-clipboard:active,
.fa-cloud-download:active {
  color: #327385;
}

@keyframes loading-animation-1 {
  0% {
    stroke-dashoffset: var(--stroke);
  }
  25% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 0;
  }
  75% {
    stroke-dashoffset: -300;
  }
  100% {
    stroke-dashoffset: -300;
  }
}

@keyframes loading-animation-2 {
  0% {
    stroke-dashoffset: var(--stroke);
  }
  23% {
    stroke-dashoffset: var(--stroke);
  }
  50% {
    stroke-dashoffset: 0;
  }
  73% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -300;
  }
}

@-moz-keyframes loading-animation-1 {
  0% {
    stroke-dashoffset: var(--stroke);
  }
  25% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 0;
  }
  75% {
    stroke-dashoffset: -300;
  }
  100% {
    stroke-dashoffset: -300;
  }
}

@-moz-keyframes loading-animation-2 {
  0% {
    stroke-dashoffset: var(--stroke);
  }
  23% {
    stroke-dashoffset: var(--stroke);
  }
  50% {
    stroke-dashoffset: 0;
  }
  73% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -300;
  }
}

@-webkit-keyframes loading-animation-1 {
  0% {
    stroke-dashoffset: var(--stroke);
  }
  25% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 0;
  }
  75% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: var(--stroke);
  }
}

@-webkit-keyframes loading-animation-2 {
  0% {
    stroke-dashoffset: var(--stroke);
  }
  23% {
    stroke-dashoffset: var(--stroke);
  }
  50% {
    stroke-dashoffset: 0;
  }
  77% {
    stroke-dashoffset: var(--stroke);
  }
  100% {
    stroke-dashoffset: var(--stroke);
  }
}
