.cookie-consent {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: black;
  color: white;
  z-index: 10000;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__actions {
    button {
      margin-left: 1rem;
    }
  }
  &__decline {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
