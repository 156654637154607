.genomes-annotations {
  display: flex;
  flex-direction: column;
  position: relative;

  &--comparison {
    margin: 10px 50px 0;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $pt-grid-size * 2;
  }

  &__filter {
    display: flex;
    margin-left: auto;

    .annotation-download {
      margin-left: 10px;

      .#{$ns}-button {
        margin: 0;
      }
    }
  }

  .no-annotations-message {
    text-align: center;
    padding-top: 50px;
  }

  .hp-filter {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
