.quality-tag {
  display: flex;
  align-items: center;
  color: white;
  padding: 1px 5px;
  margin-right: 1rem;
  font-family: brandon-grotesque, serif;
  width: 85px;
  border-radius: 4px;
  font-size: 1rem;

  &--passed {
    background: $green;
  }

  &--failed {
    background: rgb(118, 20, 12);
  }

  &--caution {
    background: rgb(247, 199, 106);
  }
  &--missing {
    background: rgb(100, 100, 100);
  }

  svg {
    margin-right: 5px;
  }
}
