.genomes-list {
  h1 {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 2rem;
  }

  .genomes-table-container {
    margin: 0 50px;

    @media (max-width: $phone-breakpoint) {
      margin: 0 10px;
    }

    &__meta {
      display: flex;
      margin-bottom: $pt-grid-size * 2;

      &__sort {
        margin-left: 0;
        margin-right: auto;

        > span {
          line-height: 30px;
          margin-right: 10px;
        }
      }

      &__search {
        min-width: 450px;
        @media (max-width: $phone-breakpoint) {
          min-width: unset;
        }
      }

      &__tags {
        padding-bottom: 5px;

        &.ocx-searchbar {
          margin-top: 5px;
          margin-right: 10px;
        }

        .search-tag {
          background-color: $primary-accent-color;
          margin-left: 10px;
        }
      }
    }

    .genomes-table {
      width: 100%;
      background: #fff;
      border-radius: 3px;
      border: solid 1px rgb(237, 238, 239);
      margin-bottom: 10px;

      .bp4-skeleton {
        display: inline-block;
        width: 100%;
      }

      td {
        vertical-align: middle;
      }

      &--atcc {
      }

      &--ocx {
      }

      .actions {
        text-align: center;
      }
    }
  }

  .download {
    cursor: pointer;
    margin-right: 10px;

    button {
      font-family: $heading-font-family;
    }
  }

  .compare-checkbox {
    margin: 0;
    text-align: center;
  }

  .taxon-name {
    max-width: 250px;
  }
  .genome-name {
    max-width: 250px;
  }
}

.paginator {
  display: flex;
  justify-content: flex-end;

  .#{$ns}-button {
    margin: 0;
  }

  button {
    text-align: center;
  }
}

.compare-button {
  margin: 0 10px 0 0;
}

.genome-count {
  float: left;
  font-size: 16px;
  padding-top: 5px;
}
