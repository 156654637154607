// Override the default fonts
@import "@blueprintjs/colors/lib/scss/colors.scss";
@import "typekit";

// Some ATCC branded colours
$purple: #2d0d62;
$medium-blue: #009fdf;
$darker-purple: #1b0342;
$hover-purple: #ccc0e0;
$grey-bg: rgb(245, 245, 246);
$green: rgb(115, 184, 161);
$search-result-colors: (#dddddd, #f8e08e, #b7dd79, #6eceb2, #009fdf, #b8381a, #e6674a, #ffc658);
$unimportant-gray: #bdbdbd;
$pt-icon-color: $purple;
$primary-accent-color: $purple;

$light-purple: #ede5f4;
$light-purple-2: #c5a5e3;

$brand: $purple;

$pt-grid-size: 10px;
$pt-navbar-height: $pt-grid-size * 8;
$pt-border-radius: 4px;

$pt-font-family: jaf-facitweb, sans-serif !default;

$heading-font-family: brandon-grotesque, serif !default;

// Import everything else
@import "all";

// Below are all ATCC-specific overrides of the One Codex styles
body {
  background-color: $white;
  margin: 0;
}

p {
  font-size: 1rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.bp4-dialog-body p {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.page-header {
  border-bottom: none;
}

.#{$ns}-tab-list {
  box-shadow: none;
  padding: 20px 50px;

  @media (max-width: $phone-breakpoint) {
    padding: 10px;
  }
}

.#{$ns}-tab-panel {
  margin: 20px 50px;

  @media (max-width: $phone-breakpoint) {
    margin: 10px;
  }
}

.#{$ns}-tab {
  line-height: 30px;
}

table.#{$ns}-html-table {
  th {
    font-weight: 600;
  }
}

.brand-logo {
  margin-top: 20px;
}

.info-tooltip {
  width: 200px;
  line-height: 16px;
  border: 1px solid black transparent;
  border-radius: 3px;
  padding: 8px 21px;
  color: white;
  background: #222;
  font-size: 90%;

  .bp4-popover2-content {
    color: white;
    background: #222;
  }
  .bp4-popover2-arrow-fill {
    fill: #222;
  }
}

.device-tooltip {
  width: 200px;
  line-height: 16px;
}

.bp4-tabs .genomes-table-container {
  margin: 0;
}

.breadcrumbs {
  margin-left: 0;
  margin-bottom: 10px;
  order: 1;
}

.page-header__main {
  flex-direction: column;
  align-items: flex-start;
}

.page-header__name {
  order: 2;
  &.capitalize {
    text-transform: capitalize;
  }
}

.#{$ns}-card {
  box-shadow: none;
  padding: 0;
}

.metadata-table thead {
  background-color: #f5f8fa;
}

.hoverable {
  &:hover {
    background-color: $grey-bg;
  }
}

.legend-popover {
  top: -70px;
  right: 0;
  position: absolute;
  z-index: 100;
}

.genome-name a,
.page-header__name a,
.download .bp4-button:not([class*="bp4-intent-"]),
.download .bp4-icon {
  color: $purple;
}

.genome-name a:hover,
.page-header__name a:hover,
.download .bp4-button:not([class*="bp4-intent-"]):hover,
.download .bp4-button:hover .bp4-icon {
  color: $medium-blue;
}

.genome-star {
  color: $purple;
  cursor: pointer;
}

.sequence-search .results-list,
.sequence-search--loading {
  box-shadow: none;
  padding: 0 ($pt-grid-size * 2);
  max-height: 2000px;
  overflow: visible;
}

.modal-checkbox {
  float: left;
  margin-bottom: 0px;
}

.atcc-link {
  color: $purple !important;

  &:hover {
    color: $hover-purple;
  }
}

footer .atcc-link {
  color: $purple;

  &:hover {
    color: $medium-blue;
  }
}

.bp4-overlay-content {
  z-index: 100;
}

.atcc-link--icon {
  margin-left: 5px;
  color: $purple !important;
}

.comparison-text {
  float: left;
  margin-top: 7px;
  margin-right: 5px;
}

.popover-icon {
  cursor: pointer;
  padding-bottom: 2px;
  padding-left: 5px;
  color: $purple;
}

.genome-tag {
  background-color: $purple;
  margin-right: 5px;
  margin-bottom: 2px;
  &:hover {
    background-color: $medium-blue !important;
  }
}

.searchbox {
  .bp4-icon-search {
    color: $purple;
  }
}

.inner-breadcrumbs {
  a {
    color: $purple;
  }

  .bp4-breadcrumb {
    font-size: 14px;
    font-style: italic;
  }
}

.atcc--uppercase {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.bp4-button.bp4-intent-primary {
  background-image: linear-gradient(45deg, #613a88 0%, #330066 100%);
  color: white;

  &.bp4-disabled {
    background-color: rgba(206, 217, 224, 0.5);
    color: rgba(92, 112, 128, 0.6);
  }
}

.bp4-button.atcc--light-gradient {
  background-image: linear-gradient(45deg, #dddae8 0%, #b5add4 100%);
  color: #140e31;
}

.atcc--white-background {
  background-color: white;
  color: black;
}

.genomes-list .taxon-name {
  font-style: italic;
}

@each $color in $search-result-colors {
  $i: index($search-result-colors, $color) - 1;

  .result-#{$i} {
    border-left: solid 5px $color;
    border-radius: 0;
    padding-left: 8px;

    .generic-link-card__body {
      background-color: adjust-color($purple, $alpha: -0.9);
      border-left: none;
    }
  }

  .result-mark-#{$i} {
    background-color: $color;
  }
}

@media (max-width: $phone-breakpoint) {
  .paginator {
    justify-content: unset;
  }
}

.profile-margin {
  margin-left: 50px;
}

// Add specific ATCC styling
@import "../components/circular-progress-bar/style";
@import "../components/quality-tag/style";
@import "../components/quality-control/style";
@import "../components/pages/home/atcc";
