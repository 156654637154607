.annotation-track-scrubber {
  width: 100%;
  height: 22px;
  margin: 0 auto;
  border-bottom: 2px #f5f5f5 solid;
  background-color: #e8e7ea;
  position: relative;
  top: 3px;
  cursor: pointer;

  &__window {
    height: 20px;
    background-color: rgba(22, 154, 153, 0.5);
    border: 1px solid rgba(22, 154, 153, 1);
    position: absolute;
    top: 3px;
    z-index: 2;
    width: 12px;
  }

  &__contig-divider {
    width: 2px;
    height: 20px;
    background-color: white;
    top: 3px;
    position: absolute;
    z-index: 1;
  }

  &__search-bookmark {
    width: 3px;
    height: 20px;
    background-color: black;
    top: -10px;
    position: absolute;
    z-index: 3;
  }
}
