.account-in-review {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-family: $heading-font-family;
    font-size: 3rem !important;
    letter-spacing: 2px;
    font-weight: normal;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  p {
    padding-left: 25px;
    padding-right: 25px;
  }

  a {
    color: $purple;

    &:hover {
      text-decoration: underline;
    }
  }
}
