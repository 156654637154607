.page-banners {
  width: 100%;
  background-color: #252e4a;
  border-top: 2px solid #2d0d62;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__banner {
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    color: #ddd;
    padding: 1rem 50px;

    button {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }

    .bp4-icon {
      color: #ddd;
    }
  }

  &__banner + &__banner {
    border-top: 2px solid #ddd;
  }
}
