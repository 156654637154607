.circular-progress-bar {
  display: flex;

  > svg {
    // By default the SVG dash will be at the wrong spot
    transform: rotate(-90deg);

    circle {
      stroke: $green;
    }

    text {
      // but the text needs to be centered
      transform-origin: center;
      transform: rotate(90deg);
    }
  }

  > div {
    display: flex;
    align-self: center;
    font-size: 1rem;
  }
}
