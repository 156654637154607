.genome-card {
  &__name {
    font-weight: 600;

    em {
      font-weight: normal;
    }
  }

  &__similarity {
    color: $pt-text-color-muted;
  }
}

@each $color in $search-result-colors {
  $i: index($search-result-colors, $color) - 1;

  .result-#{$i} {
    .generic-link-card__body {
      border-left: solid 3px $color;
      background-color: adjust-color($color, $alpha: -0.9);
    }
  }

  .result-mark-#{$i} {
    background-color: $color;
  }
}
