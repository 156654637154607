.homepage {
  &__container {
    background: $white;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 50px;

    .search-description {
      margin-top: 15px;
      font-style: italic;
      color: dimgray;
    }

    .genome-tile-container {
      display: flex;
      flex-direction: column;
      cursor: not-allowed;

      .genome-icon {
        margin-bottom: 10px;
      }

      .genome-icon {
        float: left;
      }

      .genome-info {
        float: left;
        margin-left: 10px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
      }

      .genome-subtitle {
        color: dimgray;
      }
    }
  }
}

.homepage-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 50%;

  h1 {
    margin-top: 0;
    font-size: 36px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: $pt-grid-size;
  }

  .subtitle {
    color: $pt-text-color-muted;
    max-width: 480px;
    margin: 0 0 $pt-grid-size * 3;
    font-size: 24px;
  }

  .searchbox {
    max-width: 480px;
  }

  .genome-search {
    margin-top: $pt-grid-size * 5;
  }
}

.hexagons {
  // background: linear-gradient(134deg, #16347B 0%, #0088AB 100%);
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;

  span {
    display: block;
    position: absolute;
    opacity: 0.5;
    overflow: hidden;
    background: transparent;
    width: 540px;
    height: 540px;
    /* add slash at the end of line to see the rhombus *
    outline: solid 1px red;/**/
    transform: rotate(-30deg) skewX(30deg) scaleY(0.866);
  }

  span:before {
    position: absolute;
    right: 6.7%;
    bottom: 0;
    left: 6.7%;
    top: 0;
    transform: scaleY(1.155) skewX(-30deg) rotate(30deg);
    background: linear-gradient(134deg, #16347b 0%, #0088ab 100%);
    content: "";
  }

  span:nth-child(1) {
    top: -60px;
    right: -260px;
    width: 640px;
    height: 640px;
  }

  span:nth-child(2) {
    top: 200px;
    left: 50%;
    width: 450px;
    height: 450px;
  }

  span:nth-child(3) {
    top: 600px;
    right: -60px;
    width: 800px;
    height: 800px;
  }

  &.hexagons--dark {
    background-image: linear-gradient(-137deg, #2c406b 0%, #253455 100%);

    span:before {
      background-image: linear-gradient(-137deg, #2c406b 0%, #596c97 100%);
    }
  }

  &.hexagons--light {
    background-image: linear-gradient(134deg, #1e5b6b 3%, #0c91b3 98%);

    span:before {
      background-image: linear-gradient(134deg, #1e5b6b 3%, #0c91b3 98%);
    }
  }

  &.hexagons--green {
    background-image: linear-gradient(-46deg, #1da893 2%, #44567f 100%);

    span:before {
      background-image: linear-gradient(-46deg, #1da893 2%, #44567f 100%);
    }
  }
}
