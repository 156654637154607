* {
  box-sizing: border-box;
}

html,
body,
#__next {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
}

button,
input,
select {
  font-family: $pt-font-family;
}

body {
  background-color: $pt-app-background-color;
  margin: 0;
}

h1 {
  font-size: 3.25em;
  font-weight: normal;
}

h2 {
  font-size: xx-large;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: x-large;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1rem;
}

h4 {
  font-size: large;
  font-weight: normal;
}

.app-container {
  // width: 100%;
  // margin: 0 auto;
  padding-bottom: $pt-grid-size * 5;

  &.homepage {
    width: 100%;
    margin-top: 0;
  }
}

.loading {
  height: 75vh;
  padding-top: 25vh;
  text-align: center;
  grid-column: 1/-1;
}

.underline {
  text-decoration: underline;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-icon {
  color: white;
  margin: 0;
  padding-left: 2px;
  padding-right: 4px;
}

.black-icon {
  color: black !important;
}

a {
  color: #20a7a5;
}
a:hover {
  color: #1e7075;
  text-decoration: none;
}

.bp4-input[type="search"] {
  border-radius: 3px;
}

.bp4-control-group .bp4-input[type="search"] {
  border-radius: 3px 0 0 3px;
}

.bp4-control-group .bp4-button {
  margin: 0;
}

.bp4-heading {
  font-family: $pt-font-family;
  font-weight: 600;
  font-size: 14px !important;
}

.#{$ns}-tab-list {
  background: $white;
  padding: 0 50px;
  box-shadow: 0 2px 2px 0 $pt-divider-black, 0 0 0 rgba($black, 0), 0 0 0 rgba($black, 0);
}

.#{$ns}-tab-panel {
  margin: 30px 50px;
}

.#{$ns}-tab {
  line-height: 50px;
}

.download-buttons {
  button:first-child {
    margin-right: 10px;
  }
  button:nth-child(2) {
    margin-right: 10px;
  }
}

.clickable {
  cursor: pointer;
}

.header-icon {
  padding-bottom: 2px;
  padding-left: 4px;

  &.is-active-true {
    color: black;
  }

  &.is-active-false {
    color: $unimportant-gray;
  }
}

// .#{$ns}-control .#{$ns}-control-indicator::before {
//   width: 0.8em;
//   height: 0.8em;
// }

// Remove the browser "clear" buttons on search inputs
input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="text"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

table.#{$ns}-html-table {
  th {
    font-weight: 500;
  }
}

#cookies {
  margin: 50px;

  table {
    margin-bottom: 25px;
  }
}
