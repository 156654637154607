.success-page {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-family: $heading-font-family;
    font-size: 3rem !important;
    letter-spacing: 2px;
    font-weight: normal;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  p {
    padding-left: 25px;
    padding-right: 25px;
  }

  &__link-list {
    margin: 0 auto;
    width: 100%;
    max-width: 512px;
    list-style-type: none;

    li {
      display: block;
      text-align: left;
      font-size: 1.4rem;
      font-family: $heading-font-family;
      font-weight: 400;
      line-height: 150%;
      margin-top: 1rem;
      display: flex;
      flex-direction: row;

      .bp4-icon {
        margin-right: 1rem;
        margin-top: 0.6rem;
        color: $green;
      }

      svg {
        transition: transform 0.25s;
      }
    }

    a {
      color: $purple;

      &:hover {
        text-decoration: underline;
      }
    }

    li:hover .bp4-icon svg {
      transform: scale(1.5);
    }
  }

  &__dots {
    animation-name: success-page-dots-anim;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    &--1 {
      animation-delay: 0s;
    }

    &--2 {
      animation-delay: 0.167s;
    }

    &--3 {
      animation-delay: 0.324s;
    }

    @keyframes success-page-dots-anim {
      from {
        opacity: 1;
      }

      50% {
        opacity: 0.1;
      }

      to {
        opacity: 1;
      }
    }
  }
}
