.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left {
  margin-left: 20px;
}

.margin-left-sm {
  margin-left: 10px;
}

.z-top {
  z-index: 999;
}
