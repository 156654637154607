#maintenance {
  #onecodex {
    width: 95px;
    height: 125px;
    background-size: 100% 100%;
    background-image: url("/static/OneCodexLogo.100px.png");
  }

  #onecodex-atcc {
    width: 125px;
    height: 125px;
    background-size: 100% 100%;
    background-image: url("/static/atcc/ATCC_logo_Purple_1200x1120_300dpi.jpg");
  }

  #banner-container {
    background-color: #252e4a;
    width: 100%;
    position: absolute;
    margin: -100px 0 0 0px;
    top: 420px;
    left: 0;
    border-top: 2px solid #4c5e99;
    border-bottom: 2px solid #4c5e99;
  }

  #banner {
    text-align: center;
    font-size: 24pt;
    font-family: "Open Sans", "Helvetica", "sans serif";
    color: #ddd;
    padding-top: 50px;
    padding-bottom: 40px;
  }

  #logo-container {
    width: 100px;
    position: absolute;
    height: 100px;
    margin: -50px 0 0 -50px;
    top: 160px;
    left: 50%;
  }

  a {
    color: #ddd;
  }
}
