.page-404-wrapper {
  margin: 0 auto;
  padding: 0 40px;
  width: 2160px;
  overflow: hidden;

  max-width: 40%;
  @media (max-width: 1536px) {
    max-width: 55%;
  }
  @media (max-width: 800px) {
    padding-top: 60px;
    max-width: 95%;
  }
}

.page-404-images {
  width: 100%;
  padding-bottom: 61.5%;
  height: 0;
}

.page-404-img {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
}

.page-404-non-parallax-img {
  margin-top: -100%;
}

@keyframes questionmarkanim {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    transform: translate(3.5%, -3.5%);
    opacity: 0;
  }
}

.page-404-questions {
  animation: questionmarkanim 1.75s ease-out infinite;
}
