.annotation-polygon {
  &--desaturated {
    fill-opacity: 0.5;
    stroke: $dark-gray5;
    stroke-width: 0.5;
  }

  &--selected {
    stroke: black;
    stroke-width: 1.75;
  }

  &:hover {
    stroke: black;
    stroke-width: 1.75;
  }
}

.variant-polygon {
  stroke: rgb(73, 74, 75);
  stroke-width: 1.75;
}

.annotation-polygon-hover-wrapper {
  pointer-events: none;

  div {
    pointer-events: none;
    opacity: none;
  }
}
