.plans-page {
  padding: 20px 50px;

  .plans-page-header {
    padding: 0px 50px;
    margin-top: 40px;
    .pricing-blurb {
      margin-top: 20px;
      max-width: 900px;
    }
  }

  .pricing-plans-container {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: space-evenly;
  }
}

.pricing-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  margin-bottom: 40px;
  .plan-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .plan-name {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
    .plan-description {
      width: 75%;
    }
    .plan-cost {
      font-size: 1.5rem;
      margin-top: 15px;
    }
    .plan-button {
      margin: 20px 0px;
      width: 60%;
    }
    .signup-button {
      color: black;
      background-image: linear-gradient(45deg, $light-purple 0%, $light-purple-2 100%);
    }
  }
  .features-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    .plan-feature {
      display: flex;
      margin-bottom: 15px;
      .feature-tick {
        color: $green;
      }
      .feature-text {
        margin-left: 20px;
      }
    }
  }
}
