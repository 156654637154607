.discrepancy-reports {
  padding: 0 50px;

  @media (max-width: $phone-breakpoint) {
    padding: 0 10px;
  }

  &__top {
    display: flex;
    margin-bottom: $pt-grid-size * 2;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;

    &__left {
      max-width: 580px;
    }

    &__right {
      min-width: 260px;
      text-align: right;
    }

    @media (max-width: $phone-breakpoint) {
      flex-direction: column;

      &__left {
        order: 1;
      }

      &__right {
        order: 0;
        text-align: left;
      }
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    grid-column-gap: 20px;
  }

  &__card {
    .generic-link-card__body {
      background-color: adjust-color($purple, $alpha: -0.9);
    }

    &__name {
      margin-bottom: $pt-grid-size * 0.5;
    }

    &__date {
      font-weight: normal;
      color: $pt-text-color;
    }
  }
}
